var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.modalData.showModal)?_c('BaseModal',[_c('RoomModal',{attrs:{"headline":_vm.modalData.modalHeadline,"modalType":_vm.modalData.modalType,"areaId":_vm.modalData.additionalProperties.areaId,"locationId":_vm.modalData.additionalProperties.locationId,"roomId":_vm.modalData.additionalProperties.roomId},on:{"close":function($event){_vm.modalData.showModal = false},"update":_vm.loadData}})],1):_vm._e(),_c('BaseTable',{staticClass:"mt-3",attrs:{"headers":_vm.mainheaders,"items":[_vm.location],"expanded":_vm.expandedItems,"hidePaginator":true},on:{"update:expanded":function($event){_vm.expandedItems=$event}},scopedSlots:_vm._u([(_vm.location)?{key:"title",fn:function(){return [_c('v-icon',{attrs:{"color":"primary","left":""},on:{"click":_vm.locationsLink}},[_vm._v(" mdi-arrow-left")]),_c('h3',{staticClass:"title font-weight-light text-center",staticStyle:{"align-self":"center"}},[_vm._v("Rom i "+_vm._s(_vm.location.street))]),_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Søk","single-line":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('BaseTooltipIconButton',{attrs:{"color":"primary","btnText":"Legg til rom på lokasjon","icon":"mdi-plus"},on:{"handleClick":_vm.openNewRoomModal}})]},proxy:true}:null,{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
return [_c('td',{staticClass:"pa-0",attrs:{"colspan":headers.length}},[_c('BaseTableFiltered',{attrs:{"headers":_vm.subheaders,"items":_vm.rooms,"search":_vm.search,"filter":_vm.filter},scopedSlots:_vm._u([{key:"item.isActive",fn:function(ref){
var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",on:{"click":function($event){return _vm.openEditRoomModal(item, item.name, {
                      areaId: _vm.location.areaId,
                      locationId: _vm.location.id,
                      roomId: item.id,
                    })}}},'v-icon',attrs,false),on),[_vm._v(" mdi-pencil ")])]}}],null,true)},[_c('span',[_vm._v("Rediger")])])]}}],null,true)})],1)]}},{key:"item.isActive",fn:function(ref){
                    var value = ref.value;
return [_vm._v(" "+_vm._s(value ? "Aktiv" : "Inaktiv")+" ")]}},{key:"actions",fn:function(ref){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                    var on = ref.on;
                    var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.navigateBack}},'v-icon',attrs,false),on),[_vm._v(" mdi-arrow-left ")])]}}],null,true)},[_c('span',[_vm._v("Tilbake")])])]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }